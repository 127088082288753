/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import n from "../Extent.js";
import { create as t } from "./aaBoundingRect.js";
import { newDoubleArray as r } from "./DoubleArray.js";
function i(n) {
  return n;
}
function u(n = L) {
  return i([n[0], n[1], n[2], n[3], n[4], n[5]]);
}
function a(n, t, r, i, a, e, m = u()) {
  return m[0] = n, m[1] = t, m[2] = r, m[3] = i, m[4] = a, m[5] = e, m;
}
function e(n, t = u()) {
  return t[0] = n.xmin, t[1] = n.ymin, t[2] = n.hasZ ? n.zmin : -1 / 0, t[3] = n.xmax, t[4] = n.ymax, t[5] = n.hasZ ? n.zmax : 1 / 0, t;
}
function m(n, t = u()) {
  return o(n, 0, n.length / 3, t);
}
function o(n, t, r, i = u()) {
  return d(i, K), s(i, n, t, r), i;
}
function f(n, t = r(24)) {
  const [i, u, a, e, m, o] = n;
  return t[0] = i, t[1] = u, t[2] = a, t[3] = i, t[4] = u, t[5] = o, t[6] = i, t[7] = m, t[8] = a, t[9] = i, t[10] = m, t[11] = o, t[12] = e, t[13] = u, t[14] = a, t[15] = e, t[16] = u, t[17] = o, t[18] = e, t[19] = m, t[20] = a, t[21] = e, t[22] = m, t[23] = o, t;
}
function c(t, r) {
  const i = isFinite(t[2]) || isFinite(t[5]);
  return new n(i ? {
    xmin: t[0],
    xmax: t[3],
    ymin: t[1],
    ymax: t[4],
    zmin: t[2],
    zmax: t[5],
    spatialReference: r
  } : {
    xmin: t[0],
    xmax: t[3],
    ymin: t[1],
    ymax: t[4],
    spatialReference: r
  });
}
function h(n, t, r = u()) {
  return r[0] = n[0], r[1] = n[1], r[2] = n[2], r[3] = t[0], r[4] = t[1], r[5] = t[2], r;
}
function M(n, t) {
  n[0] = Math.min(n[0], t[0]), n[1] = Math.min(n[1], t[1]), n[2] = Math.min(n[2], t[2]), n[3] = Math.max(n[3], t[3]), n[4] = Math.max(n[4], t[4]), n[5] = Math.max(n[5], t[5]);
}
function x(n, t) {
  n[0] = Math.min(n[0], t[0]), n[1] = Math.min(n[1], t[1]), n[3] = Math.max(n[3], t[2]), n[4] = Math.max(n[4], t[3]);
}
function l(n, t) {
  n[0] = Math.min(n[0], t[0]), n[1] = Math.min(n[1], t[1]), n[2] = Math.min(n[2], t[2]), n[3] = Math.max(n[3], t[0]), n[4] = Math.max(n[4], t[1]), n[5] = Math.max(n[5], t[2]);
}
function s(n, t, r = 0, i = t.length / 3) {
  let u = n[0],
    a = n[1],
    e = n[2],
    m = n[3],
    o = n[4],
    f = n[5];
  for (let c = 0; c < i; c++) u = Math.min(u, t[r + 3 * c]), a = Math.min(a, t[r + 3 * c + 1]), e = Math.min(e, t[r + 3 * c + 2]), m = Math.max(m, t[r + 3 * c]), o = Math.max(o, t[r + 3 * c + 1]), f = Math.max(f, t[r + 3 * c + 2]);
  n[0] = u, n[1] = a, n[2] = e, n[3] = m, n[4] = o, n[5] = f;
}
function I(n, t, r, i) {
  n[0] = Math.min(n[0], n[0] + t), n[3] = Math.max(n[3], n[3] + t), n[1] = Math.min(n[1], n[1] + r), n[4] = Math.max(n[4], n[4] + r), n[2] = Math.min(n[2], n[2] + i), n[5] = Math.max(n[5], n[5] + i);
}
function N(n, t, r) {
  const i = t.length;
  let u = n[0],
    a = n[1],
    e = n[2],
    m = n[3],
    o = n[4],
    f = n[5];
  if (r) for (let c = 0; c < i; c++) {
    const n = t[c];
    u = Math.min(u, n[0]), a = Math.min(a, n[1]), e = Math.min(e, n[2]), m = Math.max(m, n[0]), o = Math.max(o, n[1]), f = Math.max(f, n[2]);
  } else for (let c = 0; c < i; c++) {
    const n = t[c];
    u = Math.min(u, n[0]), a = Math.min(a, n[1]), m = Math.max(m, n[0]), o = Math.max(o, n[1]);
  }
  n[0] = u, n[1] = a, n[2] = e, n[3] = m, n[4] = o, n[5] = f;
}
function T(n) {
  for (let t = 0; t < 6; t++) if (!isFinite(n[t])) return !1;
  return !0;
}
function g(n) {
  return n[0] >= n[3] ? 0 : n[3] - n[0];
}
function y(n) {
  return n[1] >= n[4] ? 0 : n[4] - n[1];
}
function E(n) {
  return n[2] >= n[5] ? 0 : n[5] - n[2];
}
function F(n) {
  const t = g(n),
    r = E(n),
    i = y(n);
  return Math.sqrt(t * t + r * r + i * i);
}
function p(n, t = [0, 0, 0]) {
  return t[0] = n[0] + g(n) / 2, t[1] = n[1] + y(n) / 2, t[2] = n[2] + E(n) / 2, t;
}
function b(n, t = [0, 0, 0]) {
  return t[0] = g(n), t[1] = y(n), t[2] = E(n), t;
}
function z(n) {
  return Math.max(g(n), E(n), y(n));
}
function V(n, t) {
  return t[0] >= n[0] && t[1] >= n[1] && t[2] >= n[2] && t[0] <= n[3] && t[1] <= n[4] && t[2] <= n[5];
}
function Y(n, t, r) {
  return t[0] >= n[0] - r && t[1] >= n[1] - r && t[2] >= n[2] - r && t[0] <= n[3] + r && t[1] <= n[4] + r && t[2] <= n[5] + r;
}
function _(n, t) {
  return t[0] >= n[0] && t[1] >= n[1] && t[2] >= n[2] && t[3] <= n[3] && t[4] <= n[4] && t[5] <= n[5];
}
function j(n, t) {
  return Math.max(t[0], n[0]) <= Math.min(t[3], n[3]) && Math.max(t[1], n[1]) <= Math.min(t[4], n[4]) && Math.max(t[2], n[2]) <= Math.min(t[5], n[5]);
}
function A(n, t) {
  return null == t || j(n, t);
}
function R(n, t, r, i, u = n) {
  return u[0] = n[0] + t, u[1] = n[1] + r, u[2] = n[2] + i, u[3] = n[3] + t, u[4] = n[4] + r, u[5] = n[5] + i, u;
}
function G(n, t, r = n) {
  const i = n[0] + g(n) / 2,
    u = n[1] + y(n) / 2,
    a = n[2] + E(n) / 2;
  return r[0] = i + (n[0] - i) * t, r[1] = u + (n[1] - u) * t, r[2] = a + (n[2] - a) * t, r[3] = i + (n[3] - i) * t, r[4] = u + (n[4] - u) * t, r[5] = a + (n[5] - a) * t, r;
}
function O(n, t) {
  return t[0] = n[0], t[1] = n[1], t[2] = n[2], t;
}
function P(n, t) {
  return t[0] = n[3], t[1] = n[4], t[2] = n[5], t;
}
function S(n, t, r = n) {
  return r[0] = t[0], r[1] = t[1], r[2] = t[2], r !== n && (r[3] = n[3], r[4] = n[4], r[5] = n[5]), r;
}
function Z(n, t, r = n) {
  return r[3] = t[0], r[4] = t[1], r[5] = t[2], r !== n && (r[0] = n[0], r[1] = n[1], r[2] = n[2]), n;
}
function d(n, t) {
  return n[0] = t[0], n[1] = t[1], n[2] = t[2], n[3] = t[3], n[4] = t[4], n[5] = t[5], n;
}
function q(n) {
  return n ? d(n, K) : u(K);
}
function w(n, r) {
  return r || (r = t()), r[0] = n[0], r[1] = n[1], r[2] = n[3], r[3] = n[4], r;
}
function B(n, t) {
  return n[0] = t[0], n[1] = t[1], n[2] = Number.NEGATIVE_INFINITY, n[3] = t[2], n[4] = t[3], n[5] = Number.POSITIVE_INFINITY, n;
}
function D(n, t, r, i, u) {
  return n[0] = t, n[1] = r, n[2] = Number.NEGATIVE_INFINITY, n[3] = i, n[4] = u, n[5] = Number.POSITIVE_INFINITY, n;
}
function k(n) {
  return 6 === n.length;
}
function v(n) {
  return 0 === g(n) && 0 === y(n) && 0 === E(n);
}
function C(n, t, r) {
  if (null == n || null == t) return n === t;
  if (!k(n) || !k(t)) return !1;
  if (r) {
    for (let i = 0; i < n.length; i++) if (!r(n[i], t[i])) return !1;
  } else for (let i = 0; i < n.length; i++) if (n[i] !== t[i]) return !1;
  return !0;
}
function H(n, t, r, i, u, e) {
  return a(n, t, r, i, u, e, Q);
}
const J = i([-1 / 0, -1 / 0, -1 / 0, 1 / 0, 1 / 0, 1 / 0]),
  K = i([1 / 0, 1 / 0, 1 / 0, -1 / 0, -1 / 0, -1 / 0]),
  L = i([0, 0, 0, 0, 0, 0]),
  Q = u();
export { T as allFinite, p as center, _ as contains, V as containsPoint, Y as containsPointWithMargin, u as create, y as depth, F as diameter, q as empty, C as equals, M as expandWithAABB, s as expandWithBuffer, N as expandWithNestedArray, I as expandWithOffset, x as expandWithRect, l as expandWithVec3, m as fromBuffer, e as fromExtent, h as fromMinMax, B as fromRect, D as fromRectValues, o as fromSubBuffer, a as fromValues, P as getMax, O as getMin, E as height, j as intersects, A as intersectsClippingArea, k as is, v as isPoint, z as maximumDimension, K as negativeInfinity, R as offset, J as positiveInfinity, G as scale, d as set, Z as setMax, S as setMin, b as size, f as toCorners, c as toExtent, w as toRect, g as width, H as wrap, L as zero };