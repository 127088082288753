/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { nativeArrayMaxSize as r, isArray as n } from "../../core/typedArrayUtil.js";
function t(n, t = !1) {
  return n <= r ? t ? new Array(n).fill(0) : new Array(n) : new Float64Array(n);
}
function e(t) {
  return (n(t) ? t.length : t.byteLength / 8) <= r ? Array.from(t) : new Float64Array(t);
}
function a(r, n, t) {
  return Array.isArray(r) ? r.slice(n, n + t) : r.subarray(n, n + t);
}
function o(r, n) {
  for (let t = 0; t < n.length; ++t) r[t] = n[t];
  return r;
}
function y(r) {
  return Array.isArray(r) ? new Float64Array(r) : r;
}
export { o as copyInto, e as doubleArrayFrom, a as doubleSubArray, t as newDoubleArray, y as toFloat64Array };